import React from 'react';
import { Link } from 'react-router-dom';
import playIcon from '../icons/play.svg';
import paid from '../icons/paid.svg';
import '../styles/books.css';

const BooksCardUser = ({ id, name, imageUrl, price, isPaid, onPlay }) => {
    return (
        <div className="book-card">
            <Link to={`/books?title=${encodeURIComponent(name)}`} className="book-card-link">
                <img src={imageUrl} alt={name} className="book-image3" />
            </Link>
            <h4 className="book-name">{name}</h4>
            {price && isPaid && <img src={paid} alt="Paid" className="paid-icon" />}
            <button className="play-button" onClick={() => onPlay()}>
                <img src={playIcon} alt="Play" className="play-icon" />
            </button>
        </div>
    );
};

export default BooksCardUser;
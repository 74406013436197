import React, { useState, useRef, useEffect } from 'react';
import '../styles/audio.css';
import '../styles/range.css';
import prevIcon from '../icons/previous.png';
import nextIcon from '../icons/next.png';
import playIcon from '../icons/play.svg';
import pauseIcon from '../icons/pause.svg';

const FooderControl = ({ audioUrl, audioName, id, isPlaying: parentIsPlaying, onPlay, onNext, onPrev }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            const newTime = audioElement.currentTime;
            setCurrentTime(newTime);
            localStorage.setItem(`audioTime_${audioUrl}`, newTime.toString());
        };

        const handleLoadedMetadata = () => {
            setDuration(audioElement.duration);
        };

        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, [audioUrl]);

    useEffect(() => {
        setIsPlaying(parentIsPlaying);
    }, [parentIsPlaying]);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
                onPlay(null);
            } else {
                onPlay(audioRef.current);
                audioRef.current.play();
                setIsPlaying(true);
            }
        }
    };

    const handleSeek = (event) => {
        if (audioRef.current) {
            const seekTime = parseFloat(event.target.value);
            audioRef.current.currentTime = seekTime;
            setCurrentTime(seekTime);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <audio ref={audioRef} src={audioUrl} id={id} />
            <div className='container-fooder-audio'>
            <div className='fooder-content'>
                    <div className='audio-info'>
                        <h3 className='fooder-control-text'>{audioName}</h3>
                    </div>
                    <div className='buttons-control'>
                        <button className='button-cont' onClick={onPrev}>
                            <img className='icon-f' src={prevIcon} alt="Backward" />
                        </button>
                        <button className='button-cont' onClick={togglePlay}>
                            <img className='icon-play-fooder' src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} />
                        </button>
                        <button className='button-cont' onClick={onNext}>
                            <img className='icon-f' src={nextIcon} alt="Forward" />
                        </button>
                    </div>
                </div>
                <div className='content'>
                    <p className='fooder-control-text'>{formatTime(currentTime)}</p>
                    <input
                        className='audio'
                        type="range"
                        min={0}
                        max={duration}
                        value={currentTime}
                        onChange={handleSeek}
                    />
                    <p className='fooder-control-text'>{formatTime(duration)}</p>
                </div>
            </div>
        </div>
    );
};

export default FooderControl;
import React, { useState } from 'react';
import { getDatabase, ref, set, push, query, orderByChild, equalTo, get } from 'firebase/database';
import { db, auth } from '../config';
import { doc, getDoc } from '@firebase/firestore';

const PayButton = ({ productId, productName, productPrice, showSuccessModal }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePayment = async () => {
    const user = auth.currentUser;

    if (user) {
      setIsProcessing(true);

      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();

          const database = getDatabase();
          const ordersRef = ref(database, 'orders');
          const ordersQuery = query(ordersRef, orderByChild('currentUserId'), equalTo(user.uid));
          const ordersSnapshot = await get(ordersQuery);

          let hasOrderedSameBook = false;

          ordersSnapshot.forEach((childSnapshot) => {
            if (childSnapshot.val().bookId === productId) {
              hasOrderedSameBook = true;
            }
          });

          if (hasOrderedSameBook) {
            showSuccessModal('Сіздің өтінім қаралып жатыр');
          } else {
            const orderData = {
              bookId: productId,
              currentUserId: user.uid,
              number: userData?.number || '',
              bookName: productName,
              displayName: user.displayName || '',
              timestamp: Date.now(),
              status: 'pending',
              price: productPrice,
            };

            const newOrderRef = push(ordersRef);
            await set(newOrderRef, orderData);

            showSuccessModal('Аудио кітапты төлем жасағаннан кейін тыңдай аласыңыз');
          }
        } else {
          alert('User data not found!');
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        alert('Төлем өңделгенде қате пайда болды. Қайтадан байқап көріңіз.');
      } finally {
        setIsProcessing(false);
      }
    } else {
      alert('Сіз жүйеге кірмегенсіз!');
    }
  };

  return (
    <button
      className="pay-button"
      onClick={handlePayment}
      disabled={isProcessing}
    >
      {isProcessing ? 'Өңделуде...' : `Төлем жасау ${productPrice}₸`}
    </button>
  );
};

export default PayButton;
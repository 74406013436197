import React, { useState, useRef } from 'react';
import playIcon from '../icons/play.svg';
import pauseIcon from '../icons/pause.svg';
import '../styles/customAudioPlayer.css';

const CustomAudioPlayer = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className="custom-audio-player">
            <audio ref={audioRef} src={src} onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} />
            <button className="play-pause-button" onClick={togglePlayPause}>
                <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} />
            </button>
        </div>
    );
};

export default CustomAudioPlayer;
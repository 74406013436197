import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../config';

// styles
import '../styles/user.css';

// icons
import menuIcon from '../icons/menu.svg';
import userAltIcon from '../icons/profile.png';
import homeIcon from '../icons/home.png';
import searchIcon from '../icons/search.png';

// components
import BooksCardUser from './BooksCardUser';
import Read from './Read';

const User = () => {
    const [userBooks, setUserBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayName, setDisplayName] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const userPicture = localStorage.getItem('photoURL');
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedBook, setSelectedBook] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userDisplayName = localStorage.getItem('displayName');
    const userPhotoUrl = localStorage.getItem('photoURL');

    useEffect(() => {
        setDisplayName(userDisplayName);
        setPhotoUrl(userPhotoUrl);

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const fetchUserBooks = async () => {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            const bookIds = userData.books || [];
                            const fetchedBooks = await Promise.all(
                                bookIds.map(async (bookId) => {
                                    const bookDocRef = doc(db, 'books', bookId);
                                    const bookDocSnap = await getDoc(bookDocRef);
                                    if (bookDocSnap.exists()) {
                                        return { id: bookId, ...bookDocSnap.data() };
                                    } else {
                                        return null;
                                    }
                                })
                            );
                            setUserBooks(fetchedBooks.filter(book => book !== null));
                        }
                    } catch (error) {
                        console.error('Error fetching user books: ', error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchUserBooks();
                fetchFavoriteBooks();
            }
        });

        return () => unsubscribe();
    }, [userDisplayName, userPhotoUrl]);

    const fetchFavoriteBooks = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    const favoriteBookIds = userData.saves || [];
                    const fetchedFavoriteBooks = await Promise.all(
                        favoriteBookIds.map(async (bookId) => {
                            const bookDocRef = doc(db, 'books', bookId);
                            const bookDocSnap = await getDoc(bookDocRef);
                            if (bookDocSnap.exists()) {
                                return { id: bookId, ...bookDocSnap.data() };
                            }
                            return null;
                        })
                    );
                    setFavoriteBooks(fetchedFavoriteBooks.filter(book => book !== null));
                }
            }
        } catch (error) {
            console.error('Error fetching favorite books: ', error);
        }
    };

    const handleCloseMenu = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
            handleSectionToggle('')
        }
    };

    const handleReadButtonClick = (book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBook(null);
    };


    const handleLogout = () => {
        auth.signOut().then(() => {
            localStorage.clear();
        });
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('No authenticated user found.');
                }
                const storageRef = ref(storage, `profilePictures/${user.uid}_${Date.now()}_${file.name}`);

                const snapshot = await uploadBytes(storageRef, file);

                const downloadURL = await getDownloadURL(snapshot.ref);

                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, {
                    photoURL: downloadURL
                });

                setPhotoUrl(downloadURL);
                localStorage.setItem('photoURL', downloadURL);

                console.log('Profile photo updated successfully.');
            } catch (error) {
                console.error('Error uploading profile photo: ', error);
            }
        }
    };

    const handleMenuClick = (e) => {
        if (!isMenuOpen) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
        e.stopPropagation();
    };

    const handleSectionToggle = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleFeedbackChange = (e) => {
        setFeedbackText(e.target.value);
    };

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                const feedbackRef = collection(db, 'feedback');
                await addDoc(feedbackRef, {
                    userId: user.uid,
                    text: feedbackText,
                });

                setFeedbackText('');
                handleSectionToggle('feedback');
            }
        } catch (error) {
            console.error('Error submitting feedback: ', error);
        }
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    return (
        <div className={`home-container`}>
            {isMenuOpen && (
                <div className="menu">
                    <ul>
                        <ul>
                            <li className={expandedSection === 'profile' ? 'active' : ''}>
                                <p href="#" onClick={() => handleSectionToggle('profile')}>Профиль</p>
                            </li>
                            <li className={expandedSection === 'settings' ? 'active' : ''}>
                                <p href="#" onClick={() => handleSectionToggle('settings')}>Баптаулар</p>
                            </li>
                            <li className={expandedSection === 'help' ? 'active' : ''}>
                                <p href="#" onClick={() => handleSectionToggle('help')}>Көмек</p>
                            </li>
                            <li className={expandedSection === 'feedback' ? 'active' : ''}>
                                <p href="#" onClick={() => handleSectionToggle('feedback')}>Кері байланыс</p>
                            </li>
                            <li className={expandedSection === '' ? 'active' : ''}>
                                <p href="#" onClick={() => handleSectionToggle('')}>Қайырымдылық</p>
                            </li>
                        </ul>
                    </ul>
                </div>
            )
            }
            <div onClick={handleCloseMenu} className={isMenuOpen ? 'blur-background' : ''}>
                <main className="main-content-saves">
                    <div className="user-header">
                        <button className="menu-button" onClick={handleMenuClick}>
                            <img src={menuIcon} alt={userAltIcon} />
                        </button>
                        {photoUrl && <img src={photoUrl} alt="User" className="user-photo" />}
                        <h2 className="user-name">{displayName}</h2>
                    </div>
                </main>

                <main className="main-content">
                    <section className="section">
                        <h2 className="section-title">Таңдаулы</h2>
                        <div className="section-content">
                            {loading ? (
                                <div className="spinner"></div>
                            ) : (
                                favoriteBooks.map((book) => (
                                    <BooksCardUser
                                        key={book.id}
                                        name={book.title}
                                        imageUrl={book.imageUrl}
                                        price={book.price}
                                        onPlay={() => handleReadButtonClick(book)}
                                    />
                                ))
                            )}
                        </div>
                    </section>

                    <section className="section">
                        <h2 className="section-title">Сатып алынған кітаптар</h2>
                        <div className="section-content">
                            {loading ? (
                                <div className="spinner"></div>
                            ) : (
                                userBooks.map((book) => (
                                    <BooksCardUser
                                        key={book.id}
                                        name={book.title}
                                        imageUrl={book.imageUrl}
                                        price={book.price}
                                        onPlay={() => handleReadButtonClick(book)}
                                    />
                                ))
                            )}
                        </div>
                    </section>
                </main>
            </div>

            <footer className="footer">
                {expandedSection === 'profile' && (
                    <div className="settings-form">
                        <div className='settings-from-left'>
                            <img src={photoUrl} onClick={handleFileUpload} alt="Profile" className="menu-profile-photo" />
                            <h2>{userDisplayName}</h2>
                        </div>
                        <button className="settings-logout" onClick={handleLogout}>Шығу</button>
                    </div>
                )}
                {expandedSection === 'settings' && (
                    <form className="settings-form">
                        <label htmlFor="phone-number">Телефон нөмірі:</label>
                        <input
                            type="number"
                            id="phone-number"
                            className='number-input'
                            placeholder='Төлем жасау нөмірі'
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                        />
                        <button className='save-button' onClick={handlePhoneNumberChange}>Сақтау</button>
                    </form>
                )}
                {expandedSection === 'feedback' && (
                    <form className="feedback-form-container" onSubmit={handleFeedbackSubmit}>
                        <label className="feedback-form-label">Мәтін:</label>
                        <textarea
                            className="feedback-form-textarea"
                            value={feedbackText}
                            onChange={handleFeedbackChange}
                        />
                        <button className="feedback-form-submit-button" type="submit">Жіберу</button>
                    </form>
                )}
                {expandedSection === 'help' && (
                    <ul class="custom-list">
                        <li class="custom-list-item"><p>Сіздің KaspiGold нөміріңіз алдағы уақытта платформаның ішіндегі ақылы аудио кітаптарды сатып алу үшін қажет.</p></li>
                        <li class="custom-list-item"><p>Аудио кітапты сатып алуға ұсыныс жасағаннан кейін сізге төлем нөмірі KaspiGold-қа жіберіледі. Төлем жасағаннан кейін аудио кітап сізге 2 минут ішінде қолжетімді болады.</p></li>
                        <li class="custom-list-item"><p>Төлем жасау нөміріңізді ауыстырғыңыз келсе баптаулар бөліміне кіріңыз.</p></li>
                        <li class="custom-list-item"><p>Аудио кітаптардың оң жақ төбесіндегі белгі кітаптың ақылы екенін білдіреді.</p></li>
                    </ul>
                )}
                <nav className="footer-nav">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link">
                                <img src={homeIcon} alt="Home" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/books" className="nav-link">
                                <img src={searchIcon} alt="Books" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/user" className="nav-link">
                                <img
                                    style={{ borderRadius: '100%', border: '1px solid #fff' }}
                                    src={userPicture}
                                    alt={userAltIcon}
                                    className="nav-icon"
                                />
                            </Link>
                        </li>
                    </ul>
                </nav>
            </footer>
            {isModalOpen && <Read book={selectedBook} closeModal={closeModal} />}
        </div >
    );
};

export default User;
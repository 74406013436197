import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../config';

// components 
import BooksCard from './BooksCard';
import FooderControl from './FooderControl';
import NumberModal from './NumberModal';
import AuthorCard from './AuthorCard';

// styles
import '../styles/home.css';

// icons
import homeIcon from '../icons/home.png';
import searchIcon from '../icons/search.png';
import userAltIcon from '../icons/profile.png';

const Home = () => {
    const [authors, setAuthors] = useState([]);
    const [newBooks, setNewBooks] = useState([]);
    const [newSongs, setNewSongs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userBooks, setUserBooks] = useState([]);
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [playingAudio, setPlayingAudio] = useState(null);
    const [playingAudioName, setPlayingAudioName] = useState('');
    const [isAudioPlayerVisible, setIsAudioPlayerVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();
    const footerRef = useRef(null);
    const userPicture = localStorage.getItem('photoURL');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cachedAuthors = localStorage.getItem('authors');
                const cachedBooks = localStorage.getItem('newBooks');
                const cachedSongs = localStorage.getItem('newSongs');

                if (cachedAuthors && cachedBooks && cachedSongs) {
                    setAuthors(JSON.parse(cachedAuthors));
                    setNewBooks(JSON.parse(cachedBooks));
                    setNewSongs(JSON.parse(cachedSongs));
                    setLoading(false);
                } else {
                    const allBooksData = [];
                    const allSongsData = [];

                    const querySnapshot = await getDocs(collection(db, 'books'));

                    querySnapshot.forEach(doc => {
                        const bookData = doc.data();
                        if (bookData.type === 'bookAudio') {
                            allBooksData.push({
                                id: doc.id,
                                title: bookData.title,
                                imageUrl: bookData.imageUrl,
                                author: bookData.author,
                                type: bookData.type,
                                audioUrls: bookData.audioUrls,
                                audioFileNames: bookData.audioFileNames || [],
                                price: bookData.price,
                                isPaid: bookData.isPaid
                            });
                        } else {
                            allSongsData.push({
                                id: doc.id,
                                title: bookData.title,
                                imageUrl: bookData.imageUrl,
                                artist: bookData.artist,
                                type: bookData.type,
                                audioUrls: bookData.audioUrls,
                                audioFileNames: bookData.audioFileNames || [],
                                price: bookData.price
                            });
                        }
                    });

                    // Set state and cache data in local storage
                    setAuthors(allBooksData);
                    setNewBooks(allBooksData);
                    setNewSongs(allSongsData);
                    localStorage.setItem('authors', JSON.stringify(allBooksData));
                    localStorage.setItem('newBooks', JSON.stringify(allBooksData));
                    localStorage.setItem('newSongs', JSON.stringify(allSongsData));
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserBooks(userData.books || []);
                    if (!userData.number) {
                        setIsModalVisible(true);
                    }
                }
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchBooksAndUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const savesArray = userData.saves || [];

                        const favoriteBooksData = newBooks.filter(book => savesArray.includes(book.id));
                        setFavoriteBooks(favoriteBooksData);
                        setUserBooks(userData.books || []);

                        if (!userData.number) {
                            setIsModalVisible(true);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data: ', error);
                }
            }
        };

        fetchBooksAndUserData();
    }, [newBooks]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (footerRef.current && !footerRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isAudioPlayerVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isAudioPlayerVisible]);

    const handleCheckPlay = async (audioUrls, audioName, audioFileNames, bookId, isPaid) => {
        const user = auth.currentUser;
        if (user) {
            if (userBooks.includes(bookId) || isPaid === false) {
                setPlayingAudio(audioUrls[0]);
                setPlayingAudioName(audioFileNames[0]);
                setIsAudioPlayerVisible(true);
                setTimeout(() => {
                    const audioElement = document.getElementById('footer-audio-control');
                    audioElement.play();
                }, 0);
            } else {
                navigate(`/books?title=${audioName}`);
            }
        } else {
            navigate('/login');
        }
    };

    const handlePlay = (audioUrls, audioFileNames) => {
        setPlayingAudio(audioUrls[0]);
        setPlayingAudioName(audioFileNames[0]);
        setIsAudioPlayerVisible(true);
        setTimeout(() => {
            const audioElement = document.getElementById('footer-audio-control');
            audioElement.play();
        }, 0);
    };

    const handleNext = useCallback(() => {
        if (playingAudio) {
            const currentAudioList = newBooks.concat(newSongs).flatMap(item => item.audioUrls);
            const currentAudioFileNames = newBooks.concat(newSongs).flatMap(item => item.audioFileNames);
            const currentIndex = currentAudioList.indexOf(playingAudio);

            if (currentIndex !== -1 && currentIndex < currentAudioList.length - 1) {
                const nextAudio = currentAudioList[currentIndex + 1];
                const nextAudioName = currentAudioFileNames[currentIndex + 1];

                setPlayingAudio(nextAudio);
                setPlayingAudioName(nextAudioName);
                setTimeout(() => {
                    const audioElement = document.getElementById('footer-audio-control');
                    audioElement.play();
                }, 0);
            }
        }
    }, [playingAudio, newBooks, newSongs]);

    const handlePrev = useCallback(() => {
        if (playingAudio) {
            const currentAudioList = newBooks.concat(newSongs).flatMap(item => item.audioUrls);
            const currentAudioFileNames = newBooks.concat(newSongs).flatMap(item => item.audioFileNames);
            const currentIndex = currentAudioList.indexOf(playingAudio);

            if (currentIndex > 0) {
                const prevAudio = currentAudioList[currentIndex - 1];
                const prevAudioName = currentAudioFileNames[currentIndex - 1];

                setPlayingAudio(prevAudio);
                setPlayingAudioName(prevAudioName);
                setTimeout(() => {
                    const audioElement = document.getElementById('footer-audio-control');
                    audioElement.play();
                }, 0);
            }
        }
    }, [playingAudio, newBooks, newSongs]);

    const handleClose = () => {
        setIsAudioPlayerVisible(false);
        setPlayingAudio(null);
        setPlayingAudioName('');
    };

    useEffect(() => {
        const audioElement = document.getElementById('footer-audio-control');
        if (audioElement) {
            audioElement.addEventListener('ended', handleNext);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('ended', handleNext);
            }
        };
    }, [handleNext]);

    return (
        <div className="home-container">
            {isModalVisible && <NumberModal onClose={() => setIsModalVisible(false)} />}
            <div>
                <div className='background_element_1'></div>
                <div className='background_element_2'></div>
                <div className='background_element_3'></div>
                <div className='background_element_4'></div>
            </div>
            <main className="main-content">
                <section className="section">
                    <h2 className="section-title">Жаңа авторлар</h2>
                    <div className="section-content">
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            authors.map(author => (
                                <AuthorCard
                                    key={author.id}
                                    name={author.author}
                                    imageUrl={author.imageUrl}
                                />
                            ))
                        )}
                    </div>
                </section>
                <section className="section">
                    <h2 className="section-title">Жаңа кітаптар</h2>
                    <div className="section-content">
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            newBooks.map(book => (
                                <BooksCard
                                    key={book.id}
                                    id={book.id}
                                    name={book.title}
                                    imageUrl={book.imageUrl}
                                    audioUrls={book.audioUrls}
                                    audioFileNames={book.audioFileNames}
                                    price={book.price}
                                    isPaid={book.isPaid}
                                    onPlay={() => handleCheckPlay(book.audioUrls, book.title, book.audioFileNames, book.id, book.isPaid)}
                                />
                            ))
                        )}
                    </div>
                </section>

                <section className="section">
                    <h2 className="section-title">Жырлар</h2>
                    <div className="section-content">
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            newSongs.map(song => (
                                <BooksCard
                                    key={song.id}
                                    id={song.id}
                                    name={song.title}
                                    imageUrl={song.imageUrl}
                                    audioUrls={song.audioUrls}
                                    audioFileNames={song.audioFileNames}
                                    price={song.price}
                                    onPlay={() => handlePlay(song.audioUrls, song.audioFileNames)}
                                />
                            ))
                        )}
                    </div>
                </section>
                <section
                    className="section"
                    style={{ marginBottom: isAudioPlayerVisible ? '140px' : '0px', transition: 'margin-bottom 0.5s ease-in-out' }}
                >
                    <h2 className="section-title">Таңдаулы</h2>
                    <div className="section-content">
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            favoriteBooks.map(book => (
                                <BooksCard
                                    key={book.id}
                                    name={book.title}
                                    imageUrl={book.imageUrl}
                                    audioUrls={book.audioUrls}
                                    audioFileNames={book.audioFileNames}
                                    price={book.price}
                                    onPlay={() => handleCheckPlay(book.audioUrls, book.title, book.audioFileNames, book.id)}
                                />
                            ))
                        )}
                    </div>
                </section>
            </main>

            <footer className="footer" style={{ height: playingAudio ? 'auto' : 'auto' }} ref={footerRef}>
                {isAudioPlayerVisible && (
                    <div className='home-audio-container'>
                        <FooderControl
                            audioUrl={playingAudio}
                            audioName={playingAudioName}
                            onSaveTime={() => { }}
                            savedTime={null}
                            id="footer-audio-control"
                            isPlaying={true}
                            onPlay={(audioElement) => {
                                if (audioElement) {
                                    audioElement.play();
                                } else {
                                    const audioElement = document.getElementById('footer-audio-control');
                                    audioElement && audioElement.pause();
                                }
                            }}
                            onNext={handleNext}
                            onPrev={handlePrev}
                            onClose={handleClose}
                        />
                    </div>
                )}
                <nav className="footer-nav">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link">
                                <img src={homeIcon} alt="Home" className="nav-icon nav_active" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/books" className="nav-link">
                                <img src={searchIcon} alt="Books" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/user" className="nav-link">
                                <img style={{borderRadius: '100%'}} src={userPicture} alt={userAltIcon} className="nav-icon" />
                            </Link>
                        </li>
                    </ul>
                </nav>
            </footer>
        </div>
    );
};

export default Home;
import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { collection, getDocs, doc, getDoc, where, query, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

import { db, auth } from '../config';

// components
import Modal from './Read';
import PayButton from './TestPayment';
import ModalSuccess from './ModalSuccess';
import CustomAudioPlayer from './CustomAudioPlayer';

// styles
import '../styles/books.css';
import '../styles/input_style.css'

// icons
import comments from '../icons/comments.svg';
import save_1 from '../icons/bookmark_1.svg';
import save_2 from '../icons/bookmark_2.svg';
import playIcon from '../icons/play.svg';
import userAltIcon from '../icons/profile.png';
import homeIcon from '../icons/home.png';
import searchIcon from '../icons/search.png';

const Books = () => {
    const [books, setBooks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);
    const [userBooks, setUserBooks] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredIndices, setFilteredIndices] = useState([]);
    const [isCommentsOpen, setIsCommentsOpen] = useState(false);
    const [commentsData, setCommentsData] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [savedBooks, setSavedBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [genreCounts, setGenreCounts] = useState({});

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const swiperRef = useRef(null);
    const location = useLocation();
    const userPicture = localStorage.getItem('photoURL');

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const queryParams = queryString.parse(location.search);
                queryParams.title = '';
                setSearchQuery('');
                const booksCollection = collection(db, 'books');
                let booksQuery = query(booksCollection);
                if (selectedGenre) {
                    booksQuery = query(booksCollection, where('genre', '==', selectedGenre));
                }
                const booksSnapshot = await getDocs(booksQuery);
                const booksData = await Promise.all(booksSnapshot.docs.map(async doc => {
                    const data = doc.data();
                    const savesCount = data.saves ? data.saves.length : 0;
                    return { id: doc.id, ...data, savesCount };
                }));

                const genreCountMap = booksData.reduce((acc, book) => {
                    const genre = book.genre;
                    acc[genre] = (acc[genre] || 0) + 1;
                    return acc;
                }, {});

                const uniqueGenres = Object.keys(genreCountMap);
                setGenres(uniqueGenres);
                setGenreCounts(genreCountMap);
                setBooks(booksData);
                setFilteredIndices(booksData.map((_, index) => index));
                setCurrentIndex(0);
            } catch (error) {
                console.error('Error fetching books:', error);
            }
        };

        fetchBooks();
    }, [selectedGenre, location.search]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const fetchUserBooks = async () => {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            setUserBooks(userData.books || []);
                            setSavedBooks(userData.saves || []);
                        }
                    } catch (error) {
                        console.error('Error fetching user books:', error);
                    }
                };

                fetchUserBooks();
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const title = queryParams.title || '';
        if (title) {
            setSearchQuery(title);
        } else {
            setSearchQuery('');
        }
    }, [location.search]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [searchQuery]);

    const handleClickSlide = (index) => {
        setCurrentIndex(index);
    };

    const handleReadButtonClick = (book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
    };

    const isBookInUserCollection = (bookId) => {
        return userBooks.includes(bookId);
    };

    const handleCommentsToggle = async (bookId) => {
        if (isCommentsOpen && selectedBook && selectedBook.id === bookId) {
            setIsCommentsOpen(false);
            setCommentsData([]);
            setSelectedBook(null);
        } else {
            setIsCommentsOpen(true);
            const bookDocRef = doc(db, 'books', bookId);
            const bookDocSnap = await getDoc(bookDocRef);
            if (bookDocSnap.exists()) {
                const bookData = bookDocSnap.data();
                setCommentsData(bookData.comments || []);
                setSelectedBook({ id: bookId });
            }
        }
    };

    const handleCommentSubmit = async (bookId) => {
        if (newComment.trim()) {
            const bookDocRef = doc(db, 'books', bookId);
            const user = auth.currentUser;

            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};

            const comment = {
                userId: user.uid,
                text: newComment,
                displayName: userData.displayName || 'Anonymous',
                photoURL: userData.photoURL || 'default-photo-url',
                timestamp: new Date().toISOString(),
            };

            await updateDoc(bookDocRef, {
                comments: arrayUnion(comment)
            });
            setCommentsData([...commentsData, comment]);
            setNewComment('');
        }
    };

    const handleSave = async (bookId) => {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const bookDocRef = doc(db, 'books', bookId);
        if (savedBooks.includes(bookId)) {
            await updateDoc(userDocRef, {
                saves: arrayRemove(bookId)
            });
            await updateDoc(bookDocRef, {
                saves: arrayRemove(auth.currentUser.uid)
            });
            setSavedBooks(savedBooks.filter(id => id !== bookId));
        } else {
            await updateDoc(userDocRef, {
                saves: arrayUnion(bookId)
            });
            await updateDoc(bookDocRef, {
                saves: arrayUnion(auth.currentUser.uid)
            });
            setSavedBooks([...savedBooks, bookId]);
        }
    };

    const filteredBooks = books.filter(book => {
        return (
            book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            book.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
            book.sound.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    useEffect(() => {
        const handleTouchStart = (e) => {
            swiperRef.current.startX = e.touches[0].clientX;
        };

        const handleTouchMove = (e) => {
            swiperRef.current.endX = e.touches[0].clientX;
        };

        const handleTouchEnd = () => {
            const { startX, endX } = swiperRef.current;
            if (startX && endX) {
                const diff = startX - endX;
                if (diff > 50) {
                    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                } else if (diff < -50) {
                    setCurrentIndex((prevIndex) => (prevIndex < filteredIndices.length - 1 ? prevIndex + 1 : prevIndex));
                }
            }
            swiperRef.current.startX = null;
            swiperRef.current.endX = null;
        };

        const swiperElement = swiperRef.current;
        swiperElement.addEventListener('touchstart', handleTouchStart);
        swiperElement.addEventListener('touchmove', handleTouchMove);
        swiperElement.addEventListener('touchend', handleTouchEnd);

        return () => {
            swiperElement.removeEventListener('touchstart', handleTouchStart);
            swiperElement.removeEventListener('touchmove', handleTouchMove);
            swiperElement.removeEventListener('touchend', handleTouchEnd);
        };
    }, [filteredIndices.length]);

    const showSuccessModal = (message) => {
        setSuccessMessage(message);
        setIsSuccessModalOpen(true);
    };

    return (
        <div className="books-container">
            <label className="header-book">
                <input
                    type="search"
                    className="search-input"
                    placeholder="Іздеу"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </label>
            <div className='button-container'>
                <button onClick={() => setSelectedGenre('')} className={`genre-button ${selectedGenre === '' ? 'active' : ''}`}>Барлығы</button>
                {genres.map(genre => (
                    <button
                        key={genre}
                        onClick={() => setSelectedGenre(genre)}
                        className={`genre-button ${selectedGenre === genre ? 'active' : ''}`}
                    >
                        {genre} ({genreCounts[genre]})
                    </button>
                ))}
            </div>
            <main className="main-content">
                <div className="card-swiper slide-in-bck-center" ref={swiperRef}>
                    {filteredBooks.map((book, index) => {
                        const distanceFromActive = Math.abs(index - currentIndex);
                        const zIndex = books.length - distanceFromActive;
                        const rotation = -20 * (index - currentIndex);
                        const transform = `rotateY(${rotation}deg) translateZ(150px)`;

                        return (
                            <div
                                key={book.id}
                                className={`swiper-slide ${index === currentIndex ? 'active' : ''}`}
                                style={{ transform, zIndex: index === currentIndex ? books.length : zIndex }}
                                onClick={() => handleClickSlide(index)}
                            >
                                {book.type === 'bookAudio' ? (
                                    <>
                                        <img src={book.imageUrl} alt={book.title} className="card-image" />
                                        <div className="card-details">
                                            <div className="card-header">
                                                <button className='booksIconButton' onClick={() => handleCommentsToggle(book.id)}>
                                                    <img className='iconsBooks' src={comments} alt="Comments" />
                                                </button>
                                                <div className="card-footer">
                                                    {isBookInUserCollection(book.id) ? (
                                                        <button className="read-button" onClick={() => handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button>
                                                    ) : !book.isPaid ? (
                                                        <button className="read-button" onClick={() => handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button>
                                                    ) : (
                                                        <>
                                                            <div className='listen_demo'>
                                                                <p>Демо тыңдау</p>
                                                                <CustomAudioPlayer src={book.demo} />
                                                            </div>
                                                            <PayButton
                                                                productId={book.id}
                                                                productName={book.title}
                                                                productPrice={book.price}
                                                                showSuccessModal={showSuccessModal}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                <button className='booksIconButton' onClick={() => handleSave(book.id)}>
                                                    <img className='iconsBooks' src={savedBooks.includes(book.id) ? save_2 : save_1} alt="Save" />
                                                    <span className="count">{book.savesCount}</span>
                                                </button>
                                            </div>
                                            {isCommentsOpen && selectedBook && selectedBook.id === book.id && (
                                                <div className="comments-section">
                                                    {commentsData.map((comment, idx) => (
                                                        <div key={idx} className="comment">
                                                            <div className="comment-header">
                                                                <img src={comment.photoURL} alt={comment.displayName} className="comment-avatar" />
                                                                <span className="comment-author">{comment.displayName}</span>
                                                            </div>
                                                            <p>{comment.text}</p>
                                                        </div>
                                                    ))}
                                                    <div className='inputSection'>
                                                        <input
                                                            type="text"
                                                            className='commentInput'
                                                            value={newComment}
                                                            onChange={(e) => setNewComment(e.target.value)}
                                                            placeholder="Жаңа пікір енгізіңіз..."
                                                        />
                                                        <button className='commentsButton' onClick={() => handleCommentSubmit(book.id)}>Жіберу</button>
                                                    </div>
                                                </div>
                                            )}
                                            <h6 className="card-title">Атауы: {book.title}</h6>
                                            <h6 className="card-title">Авторы: {book.author}</h6>
                                            {book.sound && (
                                                <p className="card-sound">Дыбыстаушы: {book.sound}</p>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={book.imageUrl} alt={book.title} className="card-image" />
                                        <div className="card-details">
                                            <div className="card-header">
                                                <button className='booksIconButton' onClick={() => handleCommentsToggle(book.id)}>
                                                    <img className='iconsBooks' src={comments} alt="Comments" />
                                                    <span className="count">{commentsData.length}</span>
                                                </button>
                                                <button className="read-button" onClick={() => handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button>
                                                <button className='booksIconButton' onClick={() => handleSave(book.id)}>
                                                    <img className='iconsBooks' src={savedBooks.includes(book.id) ? save_2 : save_1} alt="Save" />
                                                    <span className="count">{book.savesCount}</span>
                                                </button>
                                            </div>
                                            {isCommentsOpen && selectedBook && selectedBook.id === book.id && (
                                                <div className="comments-section">
                                                    {commentsData.map((comment, idx) => (
                                                        <div key={idx} className="comment">
                                                            <div className="comment-header">
                                                                <img src={comment.photoURL} alt={comment.displayName} className="comment-avatar" />
                                                                <span className="comment-author">{comment.displayName}</span>
                                                            </div>
                                                            <p>{comment.text}</p>
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className='commentInput'
                                                            value={newComment}
                                                            onChange={(e) => setNewComment(e.target.value)}
                                                            placeholder="Жаңа пікір енгізіңіз..."
                                                        />
                                                        <button className='commentsButton' onClick={() => handleCommentSubmit(book.id)}>Жіберу</button>
                                                    </div>
                                                </div>
                                            )}
                                            <h6 className="card-title">Атауы: {book.title}</h6>
                                            <h6 className="card-title">Авторы: {book.author}</h6>
                                            {book.sound && (
                                                <p className="card-sound">Дыбыстаушы: {book.sound}</p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </main>
            {isSuccessModalOpen && (
                <ModalSuccess message={successMessage} onClose={() => setIsSuccessModalOpen(false)} />
            )}
            <footer className="footer">
                <nav className="footer-nav">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link">
                                <img src={homeIcon} alt="Home" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/books" className="nav-link">
                                <img src={searchIcon} alt="Books" className="nav-icon nav_active" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/user" className="nav-link">
                                <img style={{ borderRadius: '100%' }} src={userPicture} alt={userAltIcon} className="nav-icon" />
                            </Link>
                        </li>
                    </ul>
                </nav>
            </footer>

            {isModalOpen && <Modal book={selectedBook} closeModal={() => setIsModalOpen(false)} />}
        </div>
    );
};

export default Books;